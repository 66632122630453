<template>
  <body
      data-spy="scroll"
      data-offset="60"
      data-target=".nav__holder"
      data-cookie="on"
      id="about"
      itemscope
      itemtype="http://schema.org/WebPage"
    >
    <main class="main-wrap">
      <div class="content-wrap">
        <PrivateCinemaVideo/>             
        <PrivateCinemaSection/>
        <Footer/>     
      </div>
    </main>
    <!-- end main wrapper -->
    

  </body>
</template>

<script>
import PrivateCinemaVideo from '@/components/PrivateCinemaVideo.vue'
import PrivateCinemaSection from '@/components/PrivateCinemaSection.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: {
    PrivateCinemaVideo,
    PrivateCinemaSection,
    Footer
  }
};

</script>

<style lang="scss">

@import '../styles/base/_mixins.scss';
</style>
