<template>
  <body
      data-spy="scroll"
      data-offset="60"
      data-target=".nav__holder"
      data-cookie="on"
      id="livingspace"
      itemscope
      itemtype="http://schema.org/WebPage"
    >
    <main class="main-wrap">
      <div class="content-wrap">
        <LivingSpaceVideo/>             
        <LivingSpaceSection/>
        <Footer/>     
      </div>
    </main>
    <!-- end main wrapper -->

    

  </body>
</template>

<script>
import LivingSpaceVideo from '@/components/LivingSpaceVideo.vue'
import LivingSpaceSection from '@/components/LivingSpaceSection.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    LivingSpaceVideo,
    LivingSpaceSection,
    Footer
  }
};

</script>

<style lang="scss">

@import '../styles/base/_mixins.scss';


</style>
