<template>
  <div class="contact-polygon-grid-cont">
    <h2>
      Ready to explore your future home?
    </h2>

    <img class="contact-polygons" src="@/img/home/ContactUsPolygons.png">
    
    <router-link to="contact" class="contact-poly-button">
      Contact Us
      <div class="contact-poly-img-cont">
        <div class="contact-poly-button-img"></div>
      </div>
    </router-link>
    
  </div>  
</template>

<script>
export default{
    mounted(){

    }
};
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';

.contact-polygon-grid-cont {
  display: block;
  position: relative;
  margin: 0rem auto;
  padding: 6rem 6rem;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
  @include height-fit-content;
  width: 100vw;
  justify-items: center;
  align-content: flex-start;
  background: linear-gradient(to bottom, black, #0000ff00 20%, #0000ff00 70%, black 100%);
}

.contact-polygons {
  width: 50vw;
  height: auto;
  padding: 0rem;
  margin: 4rem 30vw 4rem auto;
  display: block;
  position: relative;
}

.contact-poly-button {
  height: 5rem;
  display: flex;
  width: 35rem;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 25rem;
  right: 15rem;
  z-index: 1;
  font-size: 2rem;
  color: #2cfdff;
  font-style: italic;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;

  @include breakpoint-laptop {
    top: 20rem;
    right: 5rem;
  }

  @include breakpoint-small-laptop {
    top: 20rem;
    right: 0rem;
  }

  @include breakpoint-below-laptop {
    top: 21rem;
    right: 0rem;
  }
}

.contact-poly-button:hover {
    color: $primary-anchor-text-hover-colour;
  .contact-poly-button-img {
    background-image: url("@/img/home/contact-poly-on.png");
    animation: pulse-animation-blue 2s infinite;
  }
}

.contact-poly-img-cont {
  height:15rem;
  width:15rem;
  margin-left: 2vw;
  background-image: url("@/img/home/contact-poly-bg.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.contact-poly-button-img {
  height: 10rem;
  width: 10rem;
  border-radius: 35rem;
  position: relative;
  background-image: url("@/img/home/contact-poly-off.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  animation: pulse-animation-green 2s infinite;
}

@keyframes pulse-animation-green {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 255, 64, 0.637);
  }
  100% {
    box-shadow: 0 0 0 2rem rgba(1, 204, 255, 0);
  }
}

@keyframes pulse-animation-blue {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 204, 255, 0.664);
  }
  100% {
    box-shadow: 0 0 0 2rem rgba(1, 204, 255, 0);
  }
}

.reviews-header {
  color: $primary-colour;
  width: 50vw;
  margin: 4rem auto 2rem;
  text-align: center;

  @include breakpoint-desktop {
  }

  @include breakpoint-small-mobile {
    margin: 1rem auto 1rem;
  }

  @include breakpoint-mobile {
    margin: 1rem auto 1rem;
  }

  @include breakpoint-tablet {
    margin: 1rem auto 1rem;
  }

  @include breakpoint-large-tablet {
    margin: 1rem auto 1rem;
  }
}

.wrapper{
  width: 100%;
  max-width: 80vw;
  height: fit-content;
  margin: 0 auto;
  padding: 0 2%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 20rem repeat(4, minmax(2rem, 15rem));
  grid-gap: 1rem;

  @include breakpoint-small-mobile {
    display: flex;
    flex-direction: column;
  }

  @include breakpoint-mobile {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  @include breakpoint-tablet {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  @include breakpoint-large-tablet {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  @include breakpoint-above-large-tablet {
    grid-template-rows: unset;
    margin-bottom: 4rem;
  }
}

.item01{
  grid-column: 1/2;
  grid-row: 1/4;
  display: flex;
  vertical-align: middle;
  justify-content: flex-start!important;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;

  @include breakpoint-large-tablet {
    display: flex;
    flex-direction: row;
  }

  p {
    font-size: inherit;
    @include breakpoint-large-tablet {
      display: flex;
      flex-direction: column;
    }
  }
}

.item02 {
  grid-column: 2/5;
  grid-row: 1/2;
  display: flex;
  flex-direction: row;
  font-size: inherit;

  @include breakpoint-small-mobile {
    display: flex;
    flex-direction: column;
  }

  @include breakpoint-mobile {
    display: flex;
    flex-direction: column;
  }

  @include breakpoint-tablet {
    display: flex;
    flex-direction: column;
  }

  @include breakpoint-large-tablet {
    display: flex;
    flex-direction: row;
  }

  p {
    font-size: inherit;
    @include breakpoint-large-tablet {
      display: flex;
      flex-direction: column;
    }
  }

  .paragraph {
    display: flex;
    flex-direction: column;
  }

  img {
    @include height-fit-content;

    @include breakpoint-large-tablet {
      width: 50%;
    }

    @include breakpoint-above-large-tablet {
      width: auto;
      height: 21rem;
    }
  }  
}

.item03 {
  grid-column: 1/2;
  grid-row: 4/4;
  display: flex;
  flex-direction: column;
  font-size: inherit;  

  @include breakpoint-small-mobile {
    display: flex;
    flex-direction: column;
  }

  @include breakpoint-mobile {
    display: flex;
    flex-direction: column;
  }

  @include breakpoint-tablet {
    display: flex;
    flex-direction: column;
  }

  @include breakpoint-large-tablet {
    display: flex;
    flex-direction: column;
  }

  p {
    font-size: 1.1rem;

    @include breakpoint-laptop {
      font-size: 1.1vw;
    }
  }
}

.item04{
  grid-column: 2/5;
  grid-row: 2/5;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: flex-start!important;
  flex-direction: column;
  flex-wrap: nowrap;
  font-size: inherit;

  @include breakpoint-small-mobile {
    display: flex;
    flex-direction: column;
  }

  @include breakpoint-mobile {
    display: flex;
    flex-direction: column;
  }

  @include breakpoint-tablet {
    display: flex;
    flex-direction: column;
  }

  @include breakpoint-large-tablet {
    display: flex;
    flex-direction: column;
  }

  p {
    padding: 3rem 4rem !important;
    font-size: 1.5rem;

    @include breakpoint-desktop {
      font-size: 1.2vw;  
    }

    @include breakpoint-small-mobile {
      font-size: 1.1rem;
      padding: 1rem 1rem !important;
    }

    @include breakpoint-mobile {
      font-size: 1.1rem;
      padding: 1rem 1rem !important;
    }

    @include breakpoint-tablet {
      font-size: inherit;
      padding: 1rem 1rem !important;
    }

    @include breakpoint-large-tablet {
      font-size: inherit;
      padding: 1rem 1rem !important;
    }
  }
}

.heading{
  grid-column: 1/5;
  @extend %center;
  font-size: 2rem;  
  text-transform: uppercase;
  background-color: #FF12A9;
  font-style: oblique;
}


[class^="item"]{
  overflow: hidden;
  border-radius: 3px;
  @extend %center;
  position: relative;
  background-color: $primary-colour;

  h1,h2,h3,h4,h5,h6 {
    margin-top: 0.5rem;
    padding: 0rem 1rem;
  }

  p {
    padding: 1rem;
  }
}

[class^="item"]::before{ 
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  @extend %center;
  color: #FFF;
  background-color: $primary-colour;
}


%center{
  display: flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
}

.meta{
  transform: translateY(100%);
  opacity: 0;
  position: absolute;
  width: 100%;
  text-align: center;
  padding-bottom: 5px;
  bottom: 0;
  color: #FFF;
  background: linear-gradient(to top, rgba(22,22,22,0.65) 30%,rgba(22,22,22,0.64) 31%,rgba(0,0,0,0.29) 69%,rgba(0,0,0,0) 100%);
  transition: 1s;
}

[class^="item"]:hover .meta{
  transform: unset;
  opacity: 1;
}

.meta .cta-list{
  list-style-type: none;
  margin: 0;
  padding: 0;
  @extend %center;
}

.meta .cta-list .cta-item{
  flex: 1;
  transition: .2s;
}

.meta .cta-list .cta-item:hover{
  transform: scale(1.2) rotate(-15deg);
  cursor: pointer;
}

.quote{
  font-size: 2em;
  color: #efefef;
  font-style: italic;
  transition: .3s;
}

.quote::after{
  content: "~ "attr(cite);
  font-size: .5em;
  display: block;
  padding-top: 5px;
}

@media (max-width: 960px){
  .heading{
    font-size: 1rem;  
    grid-column: span 2;
  }
  
  .wrapper{
    padding: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  
  [class^="item"]{
    grid-column: unset;
    grid-row: unset;
  }
}

@media (max-width: 476px){
  .heading{
    grid-column: span 1;
  }
  
  .wrapper{
    grid-template-columns: repeat(1, 1fr);
  }
}

  
  @keyframes spin {
    from{
      transform: translate(-50%,-50%) rotate(0deg)
    }
    to{
      transform: translate(-50%,-50%) rotate(1440deg);
      opacity: 0;
      content: unset;
    }
  }

@keyframes blink{
  from{}
  to{
    opacity: 0;
    content: unset;
  }
}
</style>