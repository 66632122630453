<template>
  <!-- Image Divider Section -->
  <div class="showroom">
    <div class="showroom-side-by-side">
      <div class="side">
        <div class="welcome-intro-container">
          <h2>
            Bedrooms & Bathrooms
          </h2>
          <p>
            A space truly unique to you, be that to relax, or get ready for the day ahead.
            <br/>
            <br/>
            We believe your bedroom and bathroom should reflect your mood and need at the touch of a button or voice command.
            <br/>
            <br/>
            These spaces help you to be the best version of yourself, giving you the energy and confidence to step into the world each day. Our attention to detail helps ensure this happens, day in, day out.
            <br/><br/>
            Contact us to talk through the different ways we can seamlessly install our Control4 systems to make this happen.
          </p>
          <br/>
          <br/> 
          <a href="/#Vision" class="">
            Return to Home Page
            <img class="showroom-home-button" id="" src="@/img/home/contact-poly-off.png"> 
          </a>
                 
        </div>
      </div>
      <div class="side">
        <img class="colony-logo" src="@/img/showroom/bedroom-bathroom-return.png">         
      </div>                
    </div>
  </div>
</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';


</style>