<template>
  <!-- Could make this nav a component later -->
<div id="app">
  <Navigation/>
  <router-view/>
  <modal v-show="isModalVisible" @close="closeModal" />
</div>
</template>

<script>
import Navigation from './components/Navigation.vue'

export default {
  name: 'Connected-Installations',
  components: {
    Navigation,
  },
   data() {
    return {
    };
  },
  methods: {
  },
}

</script>

<style lang="scss">

@import "@/styles/style.scss";

</style>
