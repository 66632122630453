<template>
  <body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="home"
    itemscope
    itemtype="http://schema.org/WebPage"
  >
  <main class="main-wrap">
      <div class="content-wrap">
        <HeroVideo/>
        <PolygonListNav/> 
        <Showroom/>
        <ContactSection/>
        <Footer/>   
      </div>
    </main>
    <!-- end main wrapper -->

  </body>
</template>

<script>
import HeroVideo from '@/components/HeroVideo.vue'
import PolygonListNav from '@/components/PolygonListNav.vue'
import Showroom from '@/components/Showroom.vue'
import ContactSection from "@/components/ContactSection.vue";
import Footer from '@/components/Footer.vue'

export default{
  components: {
    HeroVideo,
    PolygonListNav,
    Showroom,
    ContactSection,
    Footer
  }
};

</script>

<style lang="scss">
  
</style>