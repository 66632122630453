import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import LivingSpace from "../views/LivingSpace.vue";
import GymAndPool from "../views/GymAndPool.vue";
import BedroomAndBathroom from "../views/BedroomAndBathroom.vue";
import OutdoorAndSecurity from "../views/OutdoorAndSecurity.vue";
import KitchenAndBar from "../views/KitchenAndBar.vue";
import PrivateCinema from "../views/PrivateCinema.vue";
import Showroom from "../views/Showroom.vue";
import HomeOffice from "../views/Office.vue";
import Contact from "../views/Contact.vue";
import Privacy from "../views/Privacy.vue";






//Import all other pages to route here

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/LivingSpace',
        name: 'LivingSpace',
        component: LivingSpace
    },
    {
        path: '/Gym-and-Pool',
        name: 'Gym-and-Pool',
        component: GymAndPool
    },
    {
        path: '/Bedroom-and-Bathroom',
        name: 'Bedroom-and-Bathroom',
        component: BedroomAndBathroom
    },
    {
        path: '/Outdoor-and-Security',
        name: 'Outdoor-and-Security',
        component: OutdoorAndSecurity
    },
    {
        path: '/Kitchen-and-Bar',
        name: 'Kitchen-and-Bar',
        component: KitchenAndBar
    },   
    {
        path: '/PrivateCinema',
        name: 'PrivateCinema',
        component: PrivateCinema
    },
    {
        path: '/HomeOffice',
        name: 'HomeOffice',
        component: HomeOffice
    },
    {
        path: '/Showroom',
        name: 'Showroom',
        component: Showroom
    },
    {
        path: '/Contact',        
        name: 'Contact',
        component: Contact,          
    },
    {
        path: '/Privacy',
        name: 'Privacy',
        component: Privacy
    },
    
    
    // Add new routes here
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior(to, from, savedPosition){
        return{top: 0};
    },
    routes
})

export default router
