@@ -0,0 +1,919 @@
<template>
<body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="Privacy"
    itemscope
    itemtype="http://schema.org/WebPage"
    >   
    <main class="main-wrap">
      <div class="content-wrap">
        <div class="privacy-text-cont">
          <div class="">
            <div class="privacy-text">
              <h1>
                Privacy Policy
              </h1>
              <h4>Date: 20/01/2025</h4>
              <h5 style="text-align: left!important;">
                <em>Our contact details</em>
                <br/>                
              </h5>
              
              <p style="text-align: left!important;">
                You are not required to pay any charge for exercising your rights. If you
                make a request, we have one month to respond to you.
                Please contact us at info@connected-installation.co.uk if you wish to make a request.
              </p>

              <h5 style="text-align: left!important;">
                <em>How to complain</em>
                <br/>                
              </h5>
              <p style="text-align: left!important;">
                If you have any concerns about our use of your personal information, you
                can make a complaint to us at:<br/>
                <br/>
                You can also complain to the ICO if you are unhappy with how we have used your data.<br/><br/>
                The ICO’s address:<br/>
                Information Commissioner’s Office<br/>
                Wycliffe House<br/>
                Water Lane<br/>
                Wilmslow<br/>
                Cheshire<br/>
                SK9 5AF<br/><br/>
                Helpline number: 0303 123 1113<br/><br/>
                ICO website: <a href="https://www.ico.org.uk" target="_blank">https://www.ico.org.uk</a><br/>
              </p>
              <br/>
                <br/>
            </div>               
          </div>    
        </div>
        <Footer/>  
      </div>
    </main>
  </body>
</template>

<script>
import Footer from '@/components/Footer.vue'
export default {
  components: {
    Footer
  }, 
  mounted() {
  }
};
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';


.privacy-hero-cont {
 width: 100vw;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  @include height-fit-content;
}

.privacy-hero {
  margin: 6rem auto;
  width: 45vw;
}

.privacy-container {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 6vw;    
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top;
}

.privacy-text-cont {
  display: block;
  position: relative;
  @include height-fit-content;
  margin-top:10rem;

  .privacy-text {
    color: #ffffff!important;
    font-family: "NeueHaasDisplayLight", Helvetica, sans-serif!important;
    width: 70vw;
    margin: 0rem auto;

    h1,h2,h3,h4,h5,h6 {
      color: #ffffff!important;
      text-align: center;
      font-family: "NeueHaasDisplayThin", Helvetica, sans-serif!important;
    }

    em {
      color: #ffffff!important;
    }
    
  }
}

.privacy-policy {
  margin-top: 10rem;
  margin-left: 10vw;
  margin-right: 10vw;
  border-radius: 25px;
  color: black;
  background-image: linear-gradient(263deg, #fc622a, #f8eb70);
  padding: 2em;
}

.privacy-policy-title {
  margin-top: 2vw;
  color: #ffffff;
  text-shadow: 2px 2px 6px black;
  text-align: center;    
}

.privacy-policy-sub-title {
  color: #ffffff;
  text-shadow: 2px 2px 6px black;
  margin-left: auto;
  margin-right: auto;
  width: 70vw;
  margin-top: 2vw;

  @include breakpoint-mobile {
    font-size: 5vw;
    width: auto;
    margin-top: 5vw;
  }
}

.privacy-policy-body-text {
  padding: 2vw;
  margin-left: auto;
  margin-right: auto;
  width: 70vw;
  word-break: break-word;

  @include breakpoint-mobile {
    width: auto;
  }

  a {
    color: #162431 !important;
    cursor: pointer;
    font-weight: bold;
    text-shadow: 2px 2px 3px #ffffff;
  }
}

  
</style>