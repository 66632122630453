<template>
<!-- Header -->
  <header
    class="nav nav--always-fixed navbar"
      :class="{ 'navbar--show': !showNavbar }"
    id="reduced"
    itemtype="http://schema.org/WPHeader"
    itemscope
  >
    <div class="nav__holder">
      <div class="container-fluid container-semi-fluid">
        <div class="flex-parent">
          <div class="nav__header clearfix">
            <a href="/">
              <img class="nav-client-logo" src="@/img//branding/brand-logo.png"></a>
            
            <button
              type="button"
              class="nav__icon-toggle"
              id="nav__icon-toggle"
              data-toggle="collapse"
              data-target="#navbar-collapse"
            >
            <img class="nav-icon" src="@/img/Menu-icon-v2.png">
            </button>
          </div>
          <!-- end nav-header -->

          <!-- Navbar -->
          <nav
            data-top-menu-class="expanded"
            data-scroll-down-class="reduced"
            id="navbar-collapse"
            class="nav__wrap collapse navbar-collapse"
            itemtype="http://schema.org/SiteNavigationElement"
            itemscope="itemscope"
          >

            <ul class="nav__menu local-scroll nav-drop-down" id="onepage-nav">
              <!-- Home -->
              <li>
                <router-link to="/" class="nav-link">Home</router-link> 
              </li>

              <!-- Living Space -->
              <li>
                <router-link to="LivingSpace" class="nav-link">Living & Entertainment</router-link> 
              </li>
              
              <!-- Private Cinema -->
              <li>
                <router-link to="PrivateCinema" class="nav-link">Private Cinema</router-link> 
              </li> 

              <!-- Kitchen & Bar -->
              <li>
                <router-link to="Kitchen-and-Bar" class="nav-link">Kitchen & Bar</router-link> 
              </li>

              <!-- Private Gym & Pool -->
              <li>
                <router-link to="Gym-and-Pool" class="nav-link">Private Gym & Pool</router-link> 
              </li>

              <!-- Bedrooms & Bathrooms -->
              <li>
                <router-link to="Bedroom-and-Bathroom" class="nav-link">Bedrooms & Bathrooms</router-link> 
              </li>

              <!-- Outdoor & Security -->
              <li>
                <router-link to="Outdoor-and-Security" class="nav-link">Outdoor& Security</router-link> 
              </li>
              
              <!-- Home Office -->
              <li>
                <router-link to="HomeOffice" class="nav-link">Home Office</router-link> 
              </li>   

              <!-- Contact us -->
              <li>
                <router-link to="Contact" class="nav-link">Contact</router-link>
              </li> 

              
            </ul>

          </nav>

          <!-- end nav-wrap -->
        </div>
        <!-- end flex-parent -->
      </div>
      <!-- end container -->
    </div>
  </header>
  <!-- end navigation -->

</template>

<script>
import $ from 'jquery'
export default {
  data() {
    return{
      activeClass: 'ui-arrow-down',
      showNavbar: false,      
      lastScrollPosition: 0
    }
  },
  methods:{
    toggledroparrow(){
      
      if(this.activeClass == 'ui-arrow-down')
      {
        this.activeClass = 'ui-arrow-up'
      }
      else
      {
        this.activeClass = 'ui-arrow-down'
      }
    },
    onScroll () {
      // Get the current scroll position
      const currentScrollPosition = window.scrollY || document.documentElement.scrollTop
      // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
      if (currentScrollPosition < 0) {
        return
      }
      // Here we determine whether we need to show or hide the navbar
      this.showNavbar = currentScrollPosition < this.lastScrollPosition
      // Set the current scroll position as the last scroll position
      this.lastScrollPosition = currentScrollPosition

      // Get expanded Sub Nav Menu and on scroll down remove the show class which will collapse the Sub Nav Menu
      const expandedElement = document.getElementById("navbar-collapse");
      expandedElement.classList.remove("show")
      
    }
  },
  mounted: function(){
    window.addEventListener('scroll', this.onScroll)
    },
    beforeDestroy () {
      window.removeEventListener('scroll', this.onScroll)
  }
};
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';

.navbar-collapse {
  padding-right: 0;
  padding-left: 0;
  overflow-x: visible;
  border-top: 0 solid transparent;
  -webkit-overflow-scrolling: touch;
  background-color: #000000a6;
  backdrop-filter: blur(2px);
  background-image: linear-gradient(360deg,black,transparent);
  height: 100vh;
  margin-top: -$navbar-height;
  padding-top: $navbar-height;
  -webkit-transition: height 0.3s cubic-bezier(0, 0.23, 0.32, 0.98);
  -moz-transition: height 0.3s cubic-bezier(0, 0.23, 0.32, 0.98);
  -ms-transition: height 0.3s cubic-bezier(0, 0.23, 0.32, 0.98);
  -o-transition: height 0.3s cubic-bezier(0, 0.23, 0.32, 0.98);
  transition: height 0.3s cubic-bezier(0, 0.23, 0.32, 0.98);

  @include breakpoint-tablet {
    padding-right: 0px;
    padding-left: 0px;
    border-top: 0px;
  }
  @include breakpoint-large-tablet {
    padding-right: 0px;
    padding-left: 0px;
    border-top: 0px;
  }

  @include breakpoint-small-mobile {
    padding-right: 0px;
    padding-left: 0px;
    border-top: 0px;
  }

  .collapse:not(.show) {
    display: none
  }
  
}

.fade {
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear
}

@media (prefers-reduced-motion:reduce) {
  .fade {
    -webkit-transition: none;
    -o-transition: none;
    transition: none
  }
}

.fade:not(.show) {
  opacity: 0
}



.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  // -webkit-transition: height 0.35s ease;
  // -o-transition: height 0.35s ease;
  // transition: height 0.35s ease
}

@media (prefers-reduced-motion:reduce) {
  .collapsing {
    -webkit-transition: none;
    -o-transition: none;
    transition: none
  }
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5
}

.close:hover {
  color: #000;
  text-decoration: none
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: .75
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

a.close.disabled {
  pointer-events: none
}

.nav-client-logo {
  margin: 0.5rem 2rem 0rem 2rem;
  height: 5rem;
}
  
</style>