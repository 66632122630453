<template>
  <section class="hero">
    <div class="avoid-clicks" style="padding:56.25% 0 0 0;position:relative;">      
      <iframe src="https://player.vimeo.com/video/1003764727?h=cc76db289b&amp;badge=0&amp;muted=1&amp;autoplay=1&amp;loop=1&amp;autopause=1&amp;player_id=0&amp;controls=0&amp;app_id=58479" frameborder="0" allow="autoplay ;picture-in-picture; clipboard-write" title="Connected Installation"></iframe>
    </div>
  </section>
  
</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';


</style>