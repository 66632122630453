<template>
  <!-- Image Divider Section -->
  <div class="showroom">
    <div class="showroom-side-by-side">
      <div class="side">
        <div class="welcome-intro-container">
          <div class="adjustable-header">
            <h2>
              Visit our 
            </h2>
            <img class="control4-logo" src="@/img/home/control-4.png">
            <h2>
              showroom
            </h2>
          </div>
          <p>
            Within our showroom located at 'The Colony', we have created a number of beautiful 'spaces', which quickly demonstrate how our smart technology solutions seamlessly blend into the fabric of any home.            
            <br/><br/>
            To take a look at our showroom please click <a href="Showroom" class="blue-link"><b><i>here.</i></b></a>
          </p>          
        </div>
      </div>
      <div class="side">
        <a class="flip-card" href="Showroom">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <img class="flip-card-colony-logo" src="@/img/home/showroom-logo.png">
            </div>
            <div class="flip-card-back hexagon-clip">
              <img class="flip-card-colony-logo" src="@/img/home/showroom-logo-back.png">
            </div>
          </div>
        </a>   
      </div>                
    </div>
  </div>
</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';

.showroom-video {
  position: absolute;
  top: 0rem;
  left: -15rem;
  width: 70rem;
  height: inherit;
  aspect-ratio: 1.8;

  @include breakpoint-large-laptop {
    left: -10rem;
  }

  @include breakpoint-laptop {
    left: -10rem;
  }

  @include breakpoint-small-laptop {
    left: -10rem;
  }

  @include breakpoint-below-laptop {
  }
}

.flip-card {
  background-color: transparent;
  width: 28rem;
  height: 25rem;
  perspective: 100rem;
  margin: 2rem 0rem;

  @include breakpoint-large-laptop {
    width: 30rem;
    height: 28rem;
  }

  @include breakpoint-laptop {
    width: 30rem;
    height: 28rem;
  }

  @include breakpoint-small-laptop {
    width: 30rem;
    height: 28rem;
    margin-top: 4rem;
  }

  @include breakpoint-below-laptop {
    width: 30rem;
    height: 28rem;
  }
}

.flip-card-colony-logo {
  width: inherit;
  height: inherit;
  aspect-ratio: 0;

  @include breakpoint-below-laptop {
    width: 50rem;
    margin-top: 1rem;
  }
}

.flip-card-inner {
  position: relative;
  width: inherit;
  height: inherit;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);

  @include breakpoint-below-laptop {
    transform:none!important;
  }
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: inherit;
  height: inherit;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  color: black;
}

.flip-card-back {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);

  .flip-card-avoid-clicks {
    pointer-events: none;

    .hero-video {
      height: auto;
      width: 100vw;
      z-index: 8;
      display: block;
      position: absolute;
      top: 0rem;

      @include breakpoint-below-laptop {
        height: auto;
        width: 100vw;
      }

      .video {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    iframe {
      z-index: 6;

      button {
        display: none!important;
        opacity: 0!important;
      }
    }
  }
}

.hexagon-clip {
  display: flex;
  position: relative;
  width: inherit;
  height: inherit;
  background-color: #42424200;
  -webkit-clip-path: polygon(25% 0%, 75% 0%, 100% 51%, 75% 100%, 25% 100%, 0% 50%);
  clip-path: polygon(25% 0%, 75% 0%, 100% 51%, 75% 100%, 25% 100%, 0% 50%);
}

.blue-link {
  color: #6bb1fc;
  text-decoration: none;
  background-color: transparent;
  font-family: "NeueHaasDisplayThin";
  font-weight: bold;
}

.blue-link:hover {
  color: #238cfc;
  text-decoration: underline;  
}


</style>