<template>
  <!-- Image Divider Section -->
  <div class="showroom">
    <div class="showroom-side-by-side">
      <div class="side">
        <div class="welcome-intro-container">
          <h2>
            Private Gym & Pool
          </h2>
          <p>
            An expression of success and privacy.
            <br/><br/>
            A private gym and pool for you and your family to enjoy is a luxury few ever achieve. Our Control4 system helps these spaces achieve their potential through automated heating, lighting and AV.
            <br/><br/>
            Your gym responds to your choice of workout, turning on the large screen TV or multi speaker music system while heating and lights integrate to maintain the mood, simply and instantly. Fancy a swim? A voice command or button press is all that's needed for the cover to retract, ready for you to dive in.
            <br/><br/>
            Contact us to talk through the different ways we can seamlessly install our Control4 systems to make this happen.
          </p>
          <br/>
          <br/> 
          <a href="/#Vision" class="">
            Return to Home Page
            <img class="showroom-home-button" id="" src="@/img/home/contact-poly-off.png"> 
          </a>
                 
        </div>
      </div>
      <div class="side">
        <img class="colony-logo" src="@/img/showroom/gym-pool-return.png">         
      </div>                
    </div>
  </div>
</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';




</style>