<template >
  <!-- Image Divider Section -->
   <div class="polygon-nav-section">
    <h1 class="polygon-grid-title">
      Explore the possibilities
    </h1>
    <div class="polygon-grid-cont">  

      <div class="polygon-grid-list">
        <ul>
          <li>
            <img class="bullet" src="@/img/home/bullet.png">  
            <router-link to="LivingSpace" class="polygon-space space-a">Living & Entertainment</router-link>
            <img class="changing-polygon poly-a" id="poly-a" src="@/img/home/poly-a.png">               
          </li>
          <li>
            <img class="bullet" src="@/img/home/bullet.png"> 
            <router-link to="PrivateCinema" class="polygon-space space-b">Private Cinema</router-link>
            <img class="changing-polygon poly-b" id="poly-b" src="@/img/home/poly-b.png">                 
          </li>
          <li>
            <img class="bullet" src="@/img/home/bullet.png"> 
            <router-link to="Kitchen-and-Bar" class="polygon-space space-c">Kitchen & Bar</router-link>  
            <img class="changing-polygon poly-c" id="poly-c" src="@/img/home/poly-c.png">              
          </li>
          <li>
            <img class="bullet" src="@/img/home/bullet.png"> 
            <router-link to="Gym-and-Pool" class="polygon-space space-d">Private Gym & Pool</router-link>
            <img class="changing-polygon poly-d" id="poly-d" src="@/img/home/poly-d.png">                
          </li>
          <li>
            <img class="bullet" src="@/img/home/bullet.png"> 
            <router-link to="Bedroom-and-Bathroom" class="polygon-space space-e">Bedrooms & Bathrooms</router-link>
            <img class="changing-polygon poly-e" id="poly-e" src="@/img/home/poly-e.png">              
          </li>
          <li>
            <img class="bullet" src="@/img/home/bullet.png"> 
            <router-link to="Outdoor-and-Security" class="polygon-space space-f">Outdoor & Security</router-link>
            <img class="changing-polygon poly-f" id="poly-f" src="@/img/home/poly-f.png">                   
          </li>
          <li>
            <img class="bullet" src="@/img/home/bullet.png"> 
            <router-link to="HomeOffice" class="polygon-space space-g">Home Office</router-link>
            <img class="changing-polygon poly-g" id="poly-g" src="@/img/home/poly-g.png">
            <div class="changing-polygon radial-emitter"></div>              
          </li>

          <img class="changing-polygon poly-base" src="@/img/home/poly-base.png">
          
        </ul>
      </div>

      <div class="polygon-grid-image" id="Vision" name="Vision">
        <!-- Multiple polygon image -->
        <img class="home-polygons" src="@/img/home/home-polygons.png">
      </div>
    </div>
  </div>
</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';

.radial-emitter {
  display: none;
  transform: scale(0.78);
  border-radius: 35rem;
  animation: radial-emitter-animation-blue 4s infinite;
}

@keyframes radial-emitter-animation-green {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 255, 64, 0.637);
  }
  100% {
    box-shadow: 0 0 0 2rem rgba(1, 204, 255, 0);
  }
}

@keyframes radial-emitter-animation-blue {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 204, 255, 0.664);
  }
  100% {
    box-shadow: 0 0 0 6rem rgba(1, 204, 255, 0);
  }
}



</style>