<template>
  <!-- Image Divider Section -->
  <div class="showroom">
    <div class="showroom-side-by-side">
      <div class="side">
        <div class="welcome-intro-container">
          <h2>
            Kitchen & Bar
          </h2>
          <p>
            Create something delicious in your kitchen or relax with friends in your private bar.
            <br/><br/>
            Whatever the occcasion, both these spaces need to provide the right lighting, mood, temperature and ambience.
            <br/><br/>
            Should your need change, the space should change quickly and simply.
            <br/><br/>
            Our Control4 solutions seamlessly integrate to make these spaces bright and productive or relaxing and fun, whatever you decide.
            <br/><br/>
            Contact us to talk through the different ways we can seamlessly install our Control4 systems to make this happen.
          </p>
          <br/>
          <br/> 
          <a href="/#Vision" class="">
            Return to Home Page
            <img class="showroom-home-button" id="" src="@/img/home/contact-poly-off.png"> 
          </a>
                 
        </div>
      </div>
      <div class="side">
        <img class="colony-logo" src="@/img/showroom/kitchen-bar-return.png">         
      </div>                
    </div>
  </div>
</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';


</style>