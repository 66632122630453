<template>
  <body
      data-spy="scroll"
      data-offset="60"
      data-target=".nav__holder"
      data-cookie="on"
      id="about"
      itemscope
      itemtype="http://schema.org/WebPage"
    >
    <main class="main-wrap">
      <div class="content-wrap">
        <ShowroomVideo/>             
        <ShowroomSection/>
        <Footer/>     
      </div>
    </main>
    <!-- end main wrapper -->

    

  </body>
</template>

<script>
import ShowroomVideo from '@/components/ShowroomVideo.vue'
import ShowroomSection from '@/components/ShowroomSection.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    ShowroomVideo,
    ShowroomSection,
    Footer
  }
};

</script>

<style lang="scss">

@import '../styles/base/_mixins.scss';


</style>
