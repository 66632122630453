<template>
 <div class="contact-welcome">
    <div class="">
      <div class="contact-intro-container">
        <h2>
          Impeccable quality with dedicated customer service
        </h2>
        <br/>
        <p>
          Here at Connected Installation we take great pride in providing the highest standards of customer service to our clients. If you have any questions about our services please use the contact form below, and a member of our team will more than happy to provide you with answers.
        </p>
      </div>          
    </div>    
  </div>
</template>


<script>
export default {
    mounted(){

    }
}
</script>


<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';

.contact-welcome {
  display: block;
  position: relative;
  @include height-fit-content;
  margin: 10rem auto 5rem;
}

.contact-intro-container {
  width: 60vw;
  margin: 0rem auto;
}
</style>