<template>
  <!-- Footer -->
    <footer
      class="footer"
      itemtype="http://schema.org/WPFooter"
      itemscope
    >
      <!-- 1st Footer Row -->
      <div class="foot">
        <div class="footer__widgets">
          <div class="row footer-contact-grid-container">
            <!-- 1st Column contact -->
            <div class="col-lg-4 col-md-6 footer-logos">
              <img class="footer-client-logo"
                src="@/img/branding/footer-brand.png"
                
              />
              <div class="footer-logo-cont-horizontal">
                <a href="https://www.instagram.com/connected_av_cheshire/" target="_blank">
                  <img class="footer-client-logo" src="@/img/branding/footer-logo-insta.png"/>
                </a>
                
                <a href="https://x.com/Connected_AV" target="_blank">
                  <img class="footer-client-logo" src="@/img/branding/footer-logo-twitter.png"/>
                </a>
              
              </div>
              <img class="footer-client-logo"
                src="@/img/branding/footer-logo-control4.png"                
              />
            </div>

            <!-- 2nd Column-->
            <div class="col-lg-4 col-md-6 text-center footer-contact-list">              
              <div class="widget widget-about-us text-left text-sm-center text-lg-center">
                <div class="footer-visit-grid">
                  <div class="vertical">
                    <h3><b>Contact Us</b></h3>
                    <br/>                    
                    <p class="em">
                      <a href="mailto:info@connected-installation.co.uk"
                        class="widget-about-us__email em">
                        info@connected-installation.co.uk
                      </a>
                      <a href="tel:07555220946" class="widget-about-us__phone em">
                        Tel: 07555 220946
                      </a> 
                    </p>
                    <h3><b>Find Us</b></h3>
                    <br/>
                    <p class="em">
                      <a href="https://maps.app.goo.gl/oVTNikkdSMBczXWi7" target="_blank" class="widget-about-us__email em">
                      Connection Installation Ltd<br/>                      
                      The Colony<br/>
                      Altrincham Road<br/>
                      Wilmslow<br/>
                      Cheshire<br/> 
                      SK9 4LY
                      </a>  
                    </p>                                     
                                       
                  </div>
                </div>                
              </div>
            </div>

            <!-- 3rd Column-->
            <div class="col-lg-4 col-md-6 text-center footer-sub-nav">              
              <div class="widget widget-about-us text-left text-sm-center text-lg-center">
                <div class="footer-visit-grid">
                  <div class="vertical">
                    <div class="footer-map">
                      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d8013.034433817883!2d-2.260297116170104!3d53.33807570202!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487a529ccc20be55%3A0xa7d6603e84dd532!2sThe%20Colony%20HQ%20Wilmslow%2C%20Altrincham%20Rd%2C%20Wilmslow%20SK9%204LY!5e0!3m2!1sen!2suk!4v1724925819769!5m2!1sen!2suk" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 2nd Footer Row -->
      <div class="footer__bottom">
        <div class="container" style="margin-bottom: 0px;">
          <div class="copyright-wrap text-center row">
            <div class="col-lg-3 col-md-3 text-center">
              
            </div>
            <div class="col-lg-6 col-md-6 text-center">
              <a href="https://cyberkiln.com/" target="_blank" class="cyberkiln-container"> Crafted by <br>
                <img src="@/img/branding/cyberkiln.svg" class="cyberkiln">
              </a>
              <span class="copyright">
                &copy; 2024 - All rights reserved.<router-link to="Privacy" class="privacy-policy-link">
                  <span> Privacy Policy</span>
                </router-link>
              </span>
              <br/><br/>
            </div>
            <div class="col-lg-4 col-md-6 text-center">
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end footer -->
    <!-- <div class="footer-placeholder" id="contact"></div> -->
</template>

<script>

export default {
  name: "app",
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
  }
};
</script>

<style lang="scss"> 
@import '../styles/base/_variables.scss';
@import '../styles/base/_mixins.scss';

.privacy-policy-link:hover {
  color: $navbar-element-hover !important;
  cursor: pointer;
}

.footer__widgets .social,
.footer__widgets a:hover,
.footer__widgets a:focus {
  color: $heading-color!important;
}

.cyberkiln-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2rem;
  font-size: 1rem!important;
  .cyberkiln {
    width: 7vw;

    @include breakpoint-below-laptop {
      width: 20rem;
    }
  }
}



.footer {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0vw;
  padding: 0rem 4rem;
  background-size: 100%;
  @include height-fit-content;
  background: linear-gradient(to bottom, black, #0000008a 50%, black 100%);
  font-family: $body-font, sans-serif !important;
  font-size: 1.2rem;
  font-weight: 600;
  font-style: normal;
  font-kerning: none;
  letter-spacing: 0.1rem;
  line-height: 1.5;

  .widget {

    @include breakpoint-small-mobile {
      margin-bottom: 1rem;
    }

    @include breakpoint-mobile {
      margin-bottom: 1rem;
    }

    @include breakpoint-tablet {
      margin-bottom: 1rem;
    }

    @include breakpoint-large-tablet {
      margin-bottom: 1rem;
    }
    
  }
}

.footer-logo-cont-horizontal {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;

  .footer-client-logo {
    width: 8rem;
  }
}

.footer-logos {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;

  @include breakpoint-below-laptop {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: center;
    align-items: flex-start;
  }
}

.footer-contact-list {
  @include breakpoint-below-laptop {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    max-width: 90vw !important;
  }
}

.footer-visit-grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0rem 0rem;

  @include breakpoint-large-laptop {
    margin: 0rem 0rem;
  }

  @include breakpoint-laptop {
    margin: 0rem 0rem;
  }

  @include breakpoint-small-laptop {
    margin: 0rem 0rem;
  }

  div {
    margin:0rem;
  }

  .horizontal {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .vertical {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: flex-start;
  }

  .footer-list {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: space-evenly;
    align-items: flex-start;

    @include breakpoint-below-laptop {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: flex-start;
      justify-content: space-around;
      align-items: flex-start;
      width: 80vw;
    }

    li {
      padding: 1.6rem 0rem;
    }
  }
}

.footer-map {
  margin: 0rem!important;

  iframe {
    border: 0px;
    width: inherit;
  }
}

.footer-sub-nav .widget .footer-visit-grid {
  @include breakpoint-below-laptop {
    width: auto;
    margin: 0rem;
    display: flex;
    flex-wrap: wrap;
    margin-right: 0 !important;
    margin-left: 0 !important;
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
  }
}

.footer-sub-nav {
  @include breakpoint-below-laptop {
    width: 90vw;
    max-width: 90vw;    
  }
}

.footer-client-logo {
  width: 20rem;
  height: auto;
  padding: 2rem;
}

.footer-contact-grid-container{  
  @include breakpoint-below-laptop {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0 !important;
    margin-left: 0 !important;
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
  }

  .col-md-6 {
    @include breakpoint-large-tablet{
      
    }
  }

}

.networking-grid-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 30em;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  grid-gap: 0.5em;

  @include breakpoint-large-tablet{
    column-count: 1 !important;
    display: table-row !important;
  }

  @include breakpoint-tablet{
    column-count: 1 !important;
    display: table-row !important;
  }

  @include breakpoint-mobile {
    column-count: 2 !important;
    display: flex !important;
    flex-direction: column!important;
  }
}

.footer-logo-container {
  flex: 1 0 40%;
  max-width: 100%;
  @include breakpoint-small-mobile {
    flex: 1 1 40%;
    padding: 1.5vw;
  }
  @include breakpoint-mobile {
    flex: 1 1 20%;
    padding: 1.5vw;
  }
  @include breakpoint-tablet {
    flex: 1 1 10%;
    padding: 1em;
  }
  @include breakpoint-large-tablet {
    flex: 1 1 10%;
    padding: 1em;
  }

  @include breakpoint-small-laptop  {
    flex: 1 0 100%;
  }
}

.footer-logo {
  width: 20vw;
  cursor: pointer;
  height: auto;

  @include breakpoint-small-mobile {
    padding: 0.4em;
    width: 24vw;
  }
  @include breakpoint-mobile {
    padding: 0.4em;
    width: 24vw;
  }
  @include breakpoint-tablet {
    padding: 0.4em;
    width: 24vw;
  }
  @include breakpoint-large-tablet {
    padding: 0.4em;
    width: 24vw;
  }
  @include breakpoint-small-laptop  {
    width: 10rem;
  }
}

.footer__bottom {
  z-index: 5;
  padding-bottom: 0em;
  margin-top: 4rem;

  @include breakpoint-small-mobile {
    padding-bottom: 0;
    margin-top: 0;
  }
  @include breakpoint-mobile {
    padding-bottom: 0;
    margin-top: 0;
  }

  @include breakpoint-tablet {
    margin-top: 0;
    padding-bottom: 2em;
  }

  @include breakpoint-large-tablet {
    padding-bottom: 0;
    margin-top: 0;
  }

  @include breakpoint-large-laptop {
    margin-top: 0;
  }

  container {
    @include breakpoint-tablet {
      margin: 0px auto;
    }

    @include breakpoint-large-tablet {
      margin: 0px auto;
    }
  }
}

.em {             
  font-family:$body-font!important; 
  color: $color-embellish!important;
  font-weight: bold;
}

</style>