<template>  
  <body
      data-spy="scroll"
      data-offset="60"
      data-target=".nav__holder"
      data-cookie="on"
      id="contact"
      itemscope
      itemtype="http://schema.org/WebPage"
    >
    
    <main class="main-wrap">
      <div class="content-wrap">
        <ContactWelcome/>
        <ContactForm />
        <Footer/>  
      </div>
    </main>
  </body>
</template>

<script>
import ContactForm from "@/components/ContactForm.vue";
import ContactWelcome from "@/components/ContactWelcome.vue";
import Footer from '@/components/Footer.vue'
export default{  
 name: "Contact",
  components: {
    ContactForm,
    ContactWelcome,
    Footer
  },
  metaInfo() {
    return {
    }
  }, 
  data() {
    return {
    };
  },
};

</script>

<style lang="scss">
</style>