<template>
  <section class="hero">
    <div class="avoid-clicks" style="padding:56.25% 0 0 0;position:relative;">      
      <!-- <video class="hero-video" autoplay muted loop> 
        <source src="@/video/home_video_compressed.mp4" class="video" type="video/mp4" title="Connected Installation">
      </video> -->
      <iframe src="https://player.vimeo.com/video/1000673304?h=21e2d520f8&amp;badge=0&amp;muted=1&amp;autoplay=1&amp;loop=1&amp;autopause=1&amp;player_id=0&amp;controls=0&amp;app_id=58479" frameborder="0" allow="autoplay ;picture-in-picture; clipboard-write" title="Connected Installation"></iframe>
      
    </div>
  </section>
  
</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';

.avoid-clicks {
  pointer-events: none;
  z-index: 7;
  top: 0rem;
  height: auto;
  width: 100vw;
  min-width: 100vw;

  @include breakpoint-below-laptop {
    height: auto;
    width: 100vw;
  }

  .hero-video {
    height: inherit;
    width: inherit;
    z-index: 8;
    display: block;
    position: absolute;
    top: 0rem;

    @include breakpoint-below-laptop {
      height: auto;
      width: 100vw;
    }

    .video {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  iframe {
    z-index: 6;
    position: absolute;
    top:inherit;
    left:inherit;
    width:100%;
    height:100%;
    transform:scale(1.1);
    transform-origin: top;
    
    @include breakpoint-imac-27inch {
      height:115vh;
      transform:scale(1);
      // top:-5vw;
    }

    // @include breakpoint-extra-large {
    //   height:100vh;
    //   transform:scale(1.1);
    // }

    // @include breakpoint-desktop {
    //   height:100vh;
    //   transform:scale(1.1);
    // }

    // @include breakpoint-large-laptop{
    //   height:100vh;
    //   transform:scale(1.1);
    //   // top: -10vw;
    // }

    // @include breakpoint-laptop{
    //   height:100vh;
    //   transform:scale(1.1);
    //   // top: -25vw;
    // }

    // @include breakpoint-small-laptop{
    //   height:100vh;
    //   transform:scale(1.1);
    //   // top: -25vw;
    // }

    // @include breakpoint-ipadpro12inch-landscape {
    //   height:100vh;
    //   transform:scale(1);
    //   top: -55vw;
    // }

    // @include breakpoint-ipadpro12inch-portrait {
    //   height:115vh;
    //   transform:scale(3.2);
    //   top: -157vw;
    // }
    

    // @include breakpoint-large-tablet{
    //   height:115vh;
    //   transform: scale(2.5);
    //   top: -100vw;
    // }

    // @include breakpoint-tablet{
    //   height:115vh;
    //   transform:scale(3.8);
    //   top: 0;
    // }

    // @include breakpoint-mobile{
    //   height:115vh;
    //   transform: scale(4.8);
    //   top: 0;
    // }

    // @include breakpoint-small-mobile{
    //   height:115vh;
    //   transform: scale(5.4);
    //   top: 0;
    // }

    

    button {
      display: none!important;
      opacity: 0!important;
    }
  }
}

.video-bg {
  @include width-fill-available;
  min-width: 100vw;
  // aspect-ratio: 1;
  display: block;
  position: absolute;
  @include height-fill-available;
  top: -8rem;
  overflow: hidden !important;
  z-index: 5;
  opacity: 0;
  background-color: black;
  animation-name: splash;
  animation-duration: 5s;
  animation-iteration-count: 1;
  animation-direction: normal;
  transform:scale(2);
  pointer-events: none;

  @include breakpoint-large-tablet{
    @include height-fill-available;
    transform: scale(2.6);
    top: 0;
  }

  @include breakpoint-tablet{
    @include height-fill-available;
    transform:scale(1);
    top: 0;
  }

  @include breakpoint-mobile{
    @include height-fill-available;
    transform: scale(1);
    top: 0;
  }

  @include breakpoint-small-mobile{
    @include height-fill-available;
    transform: scale(1);
    top: 0;
  }  

}

.hero-video-cover {
  @include width-fill-available;
  @include breakpoint-extra-large {
      @include height-fill-available;
      transform:scale(1.5);
    }

    @include breakpoint-desktop {
      @include height-fill-available;
      transform:scale(1.5);
    }

    @include breakpoint-large-laptop{
      @include height-fill-available;
      transform:scale(1.6);
      top: 0;
    }

    @include breakpoint-laptop{
      @include height-fill-available;
      transform:scale(1.8);
      top: 0;
    }

    @include breakpoint-small-laptop{
      @include height-fill-available;
      transform:scale(2.1);
      top: 0;
    }

    @include breakpoint-large-tablet{
      @include height-fill-available;
      transform: scale(2.6);
      top: 0;
    }

    @include breakpoint-tablet{
      height: auto;
      transform:scale(2.5);
      transform-origin: top;
      top: 0;
    }

    @include breakpoint-mobile{
      height: auto;
      transform: scale(2);
      transform-origin: top;
      top: 0;
    }

    @include breakpoint-small-mobile{
      height: auto;
      transform: scale(2.0);
      transform-origin: top;
      top: 0;
    }  

}

@keyframes splash {
  0% {
    z-index: 8;
    opacity: 1;
    transform: translate(0, 0);
  }
  99% {
    z-index: 8;
    opacity: 1;
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, -150vh);
    opacity: 0;
    z-index: 5;
  }
}

</style>