<template>
  <!-- Image Divider Section -->
  <div class="showroom">
    <div class="showroom-side-by-side">
      <div class="side">
        <div class="welcome-intro-container">
          <h2>
            Visit our <img class="control4-logo" src="@/img/home/control-4.png"> showroom
          </h2>
          <p>
            Our Control4 showroom is located at 'The Colony', a prestigious business and event venue in the heart of Cheshire, just a few minutes outside Wilmslow.
            <br/><br/>
            Catering to designers, architects and statement home owners, this private complex boasts the latest Control4 integration.
            <br/><br/>
            We have created a number of beautiful 'spaces', which quickly demonstrate how our smart technology solutions seamlessly blend into the fabric of any home.
            <br/><br/>
            They also provide an example of our dedication to personal service, quality & attention to detail.
          </p>
          <br/>
          <a href="Contact" class="">
            To arrange a personal, no obligation visit, please click <a href="Contact" class="blue-link"><b><i>here.</i></b></a>
            <a href="Contact"><img class="showroom-home-button" id="" src="@/img/home/contact-poly-off.png"> </a>
          </a>
                 
        </div>
      </div>
      <div class="side">
        <img class="colony-logo" src="@/img/showroom/showroom-return.png">         
      </div>                
    </div>
  </div>
</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';

</style>